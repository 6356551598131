<template>
  <el-main>
    <p>体质问卷</p>
    <el-row>
      <el-col :span="24">
        <div class="m-table">
          <div class="tip" v-if="setp == 1">
            <p>请在以下十一组症候群中，选择与您现状最接近的1～3组</p>
          </div>
          <div v-if="setp == 1">
            <el-form v-model="form">
              <el-form-item label="性别">
                <el-radio-group v-model="form.sex" size="medium">
                  <el-radio border label="男"></el-radio>
                  <el-radio border label="女"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <!-- 简义体质表 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            disabled
            @selection-change="handleSelectionChange"
            row-key="id"
            v-show="setp == 1"
            @row-click="handleRowClick"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
              :selectable="checkSelectable"
            >
            </el-table-column>
            <!-- <el-table-column label="体质类型" width="120" prop="type">
            </el-table-column> -->
            <el-table-column prop="symptom" label="症状">
              <template slot-scope="scope">
                <p
                  v-for="(str, index) in scope.row.symptom.split('；')"
                  :key="index"
                >
                  {{ str.indexOf("。") != -1 ? str : `${str}；` }}
                </p>
              </template>
            </el-table-column>
          </el-table>
          <!-- 体质回答项 -->
          <div v-if="setp == 2">
            <el-form label-position="left" class="questions-form">
              <el-form-item v-for="(item, index) in questions" :key="index">
                <!-- <p style="color: red">{{ item.name }}</p> -->
                <el-form-item
                  :label="item2.name"
                  v-for="(item2, index) in item.values"
                  :key="index"
                >
                  <el-radio-group
                    v-model="item2.score"
                    ref="radio"
                    v-removeAriaHidden
                  >
                    <el-radio
                      :label="value"
                      v-for="(value, key, index) in item2.values"
                      :key="index"
                      aria-hidden="true"
                    >
                      {{ key }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 图表 -->
          <div v-show="setp == 3">
            <div class="echart" id="main"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div style="margin-top: 20px">
          <el-button @click="preStep" type="default" :disabled="setp == 1"
            >上一步</el-button
          >
          <el-button
            @click="nextStep"
            type="primary"
            :disabled="setp == 3 || !form.sex || !multipleSelection"
            >下一步</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-main>
</template>
  
  <script>
export default {
  name: "constitutionPage",
  data() {
    return {
      tableData: [
        {
          id: "1",
          type: "气虚质",
          symptom: "疲乏无力,少气声低,懒得说话精神不振；易出虚汗；易患外感；",
        },
        {
          id: "2",
          type: "血虚质",
          symptom:
            "化验：红血球，血红蛋白偏低。面色苍白或黄而无光泽；唇、舌色淡白；头晕眼花；健忘；女性月经色淡而量少。",
        },
        {
          id: "3",
          type: "阳虚质",
          symptom:
            "平素怕冷，或胃、背、腹、腰、膝、手足等局部有冷感；饮食喜温热，耐受温热药物；吃喝凉东西或寒凉药物感到不适；易感风寒；大便稀；小便清长；性欲偏弱；舌淡白。",
        },
        {
          id: "4",
          type: "阴虚质",
          symptom:
            "身体易有低热感，或易手足心热；易口干，渴喜冷饮；进食干燥或煎炸之品则不适；易上火；大便干燥；面部易有烘热感；舌体偏红；舌上少苔或少水份；",
        },
        {
          id: "5",
          type: "痰湿质",
          symptom:
            "体形偏胖；面部易有油腻感，平素痰多；口中有有黏腻感；头、身重沉而困倦；大便常粘；舌淡白而胖大，有齿印；舌苔腻；妇女带下量多而色白。",
        },

        {
          id: "7",
          type: "湿热质",
          symptom:
            "头、身重沉而困倦；油性皮肤易生痤疮、粉刺、疮疖；口中有腻感；口苦；多汗且粘；常大便粘，易有解不尽的感觉；小便黄；舌体偏红；舌苔黄腻；带下色黄(女)/阴囊潮湿而臊味重(男)。",
        },
        {
          id: "8",
          type: "瘀暗质",
          symptom:
            "面色晦暗，或黄褐色斑；皮肤色素沉着；体表易出现青紫瘀斑(皮下出血)；易患疼痛；口唇色偏暗；舌偏暗或有瘀斑；或舌下络脉青紫女性月经色紫暗,或常挟血块。",
        },
        {
          id: "9",
          type: "气郁质",
          symptom:
            "常觉精神郁闷、情绪低沉；或易精神紧张、焦虑不安；常无缘无故地叹气；易胁肋、胸部、乳房胀闷，或走窜疼痛；",
        },
        {
          id: "10",
          type: "易敏质",
          symptom:
            "容易过敏(对药物、食物、气味、花粉、气候变化)；常鼻塞、打喷嚏、流清涕；皮肤容易起荨麻疹(风团、风疹块、风疙瘩)；易因季节变化、温度变化或异味等原因而咳喘；",
        },
      ],
      tableDataTemp: [],
      questionsMapTemp: {},
      questionsMap: {
        气虚: [
          {
            name: "神倦泛力",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          { name: "气喘", values: { 轻: 10, 重: 20, 较重: 30 }, score: 0 },
          { name: "自汗", values: { 轻: 10, 重: 20, 较重: 30 }, score: 0 },
        ],
        血虚: [
          {
            name: "唇爪淡白",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "头晕乏力，眼花心悸",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "失眠多梦",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
        阳虚: [
          {
            name: "畏寒肢冷",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "面色苍白",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "大便溏薄",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
        阴虚: [
          {
            name: "心烦失眠、口燥咽干",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "盗汗遗精、性欲亢进",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "两颧潮红、小便短黄、大便干结",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
        痰湿: [
          {
            name: "胸闷， 痰多",
            value: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "容易困倦， 身重 不爽",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "喜食肥甘醇酒，舌体胖大",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
        气郁: [
          {
            name: "平素性情忧郁，闷闷不乐",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "胸胁胀痛或走窜疼痛，喜欢长出气",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "女性乳房胀痛，经期腹痛、睡眠较差，食欲减退，大便干",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
        血瘀: [
          {
            name: "面色晦黯，皮肤粗糙呈褐色，色素沉着，或有紫斑",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
          {
            name: "口唇黯淡，舌质青紫或有瘀点",
            values: { 轻: 10, 重: 20, 较重: 30 },
            score: 0,
          },
        ],
      },
      multipleSelection: [],
      questions: [
        {
          name: "气虚",
          values: [
            {
              name: "神倦泛力",
              values: { 轻: "1", 重: "2", 较重: "3" },
              score: 0,
            },
            { name: "气喘", values: { 轻: "1", 重: "2", 较重: "3" }, score: 0 },
            { name: "自汗", values: { 轻: "1", 重: "2", 较重: "3" }, score: 0 },
          ],
        },
        {
          name: "阳虚",
          values: [
            {
              name: "畏寒肢冷",
              values: { 轻: "1", 重: "2", 较重: "3" },
              score: 0,
            },
            {
              name: "大便溏薄",
              values: { 轻: "1", 重: "2", 较重: "3" },
              score: 0,
            },
            {
              name: "小便清长",
              values: { 轻: "1", 重: "2", 较重: "3" },
              score: 0,
            },
          ],
        },
      ],
      form: {
        sex: "",
      },
      setp: 1,
    };
  },
  mounted() {},
  methods: {
    // toggleSelection(rows) {
    //   debugger
    //   console.log("row",rows);
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      if (val.length > 2) {
        let filteredArray = this.tableData.filter((item) => {
          return val.includes(item);
        });
        this.tableData = [...filteredArray];
      } else {
        this.tableData = [...this.tableDataTemp];
      }

      this.questions = this.handleData(this.multipleSelection);
    },
    handleRowClick(row) {
      if (!this.form.sex) return;
      // 从已选中数据中 判断当前点击的是否被选中
      const selected = this.multipleSelection.some(
        (item) => item.id === row.id
      ); // 是取消选择还是选中
      if (!selected) {
        // 不包含   代表选择
        this.multipleSelection.push(row);
        this.$refs["multipleTable"].toggleRowSelection(row, true);
      } else {
        // 取消选择
        var finalArr = this.multipleSelection.filter((item) => {
          return item.id !== row.id;
        });
        this.multipleSelection = finalArr; // 取消后剩余选中的

        this.$refs["multipleTable"].toggleRowSelection(row, false);
      }
    },
    confirm() {
      this.$refs.multipleTable.data.forEach((row) => {
        this.$refs.multipleTable.toggleRowSelection(row);
      });
    },
    initChart() {
      // 基于准备好的 DOM，初始化 ECharts 实例
      const myChart = this.$echarts.init(document.getElementById("main"));
      let xdata = this.questions.map((item) => item.name);
      let keepTheScore = this.questions.map((item) =>
        item.values.map((val) => {
          let score = val.score * val.weight * 25;
          if (this.form.sex == "男" && val.man) {
            score *= val.man;
          }
          if (this.form.sex == "女" && val.gril) {
            score *= val.gril;
          }

          console.log("类型:", val.name);
          console.log("分数：", score);
          return score;
        })
      );
      let score = [];
      keepTheScore.forEach((num) => {
        let sum = num.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });
        score.push(Math.round(sum));
      });
      console.log("xdata", score);
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: "体质",
        },
        tooltip: {},

        xAxis: {
          type: "category",
          //data: ["气虚", "血虚", "阳虚", "阴虚", "痰湿", "气郁", "血瘀"],
          data: xdata,
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
        },
        series: [
          {
            type: "bar",
            data: score,
            markLine: {
              symbol: "none",
              tooltip: { show: false },
              data: [
                {
                  name: "异常",
                  yAxis: 38,
                  lineStyle: {
                    color: "red", // 设置标记线的颜色为红色
                  },
                  label: {
                    normal: {
                      formatter: function (params) {
                        console.log(params);
                        return "↑异常";
                      },
                    },
                  },
                },
                {
                  name: "正常",
                  yAxis: 30,
                  type: "average",
                  min: 10,
                  max: 30,
                  lineStyle: {
                    color: "green", // 设置标记线的颜色为红色
                  },
                  label: {
                    normal: {
                      formatter: function (params) {
                        console.log(params);
                        return "↓正常";
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表
      myChart.setOption(option);
    },
    nextStep() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请勾选一条你相似的症状！",
          type: "warning",
        });
      } else {
        this.setp > 3 ? this.setp == 3 : this.setp++;
      }
      if (this.setp == 3) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    preStep() {
      this.setp < 1 ? this.setp == 1 : this.setp--;
    },
    handleData(data) {
      let selArr = [];
      data.forEach((item) => {
        selArr.push({ name: item.type, values: this.questionsMap[item.type] });
      });
      return selArr;
    },
    getData() {
      fetch("/data/smiple.json")
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          this.tableData = data.data;
          this.tableDataTemp = data.data;
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });

      fetch("/data/qustions.json")
        .then((res) => res.json())
        .then((data) => {
          this.questionsMap = data;
          this.questionsMapTemp = data;
        })
        .catch((error) => {
          console.error("Error fetching JSON:", error);
        });
    },
    getRowKeys(row) {
      return row.id;
    },
    checkSelectable() {
      return this.form.sex;
    },
    updateData() {},
  },
  created() {
    this.getData();
  },
  watch: {
    "form.sex": function (val, oldVal) {
      console.log("new: %s, old: %s", val, oldVal);
      this.questionsMap = { ...this.questionsMapTemp };
      for (let key in this.questionsMap) {
        let filterItem = this.questionsMap[key].filter((item) => {
          let isFilter = true;
          if (item.sex && val == "男" && item.sex == "女") {
            isFilter = false;
          }

          return isFilter;
        });
        this.questionsMap[key] = filterItem;
      }
      this.questions = this.handleData(this.multipleSelection);
    },
  },
};
</script>
<style lang="stylus">
@import ('../styles/index.styl');

.echart {
  min-width: 400px;
  height: 400px;
}
</style>